import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { AppTheme } from "layout/AppTheme";
import {
  ChartLabelType,
  ChartLabels,
} from "../../analytic-graph-card/analytic-graph-card-legend-list/analytic-graph-card-chart-labels";
import { formatNumber } from "helpers/formatNumber";
import { OrdersReportChart } from "@next/modules/analytics/redux";
import { useSelector } from "react-redux";
import { selectAnalyticFilters } from "@next/modules/analytics/redux/selectors";

const colors = [
  AppTheme.palette.secondary.main,
  AppTheme.palette.primary.main,
  AppTheme.palette.primary.main,
];

export const OrdersReportPOVolumeChart: React.FC<OrdersReportChart> = ({ id, x_axis, y_axes }) => {
  const { t } = useTranslation();
  const analyticFilters = useSelector(selectAnalyticFilters);
  const chartLabels = [
    {
      name: `${t("analytics:ordersReport:charts:volume:itemsSent")}`,
      type: ChartLabelType.COLUMN,
    },
    {
      name: `${t("analytics:ordersReport:charts:volume:totalValue", {
        currency: analyticFilters.currency,
      })}`,
      type: ChartLabelType.COLUMN,
    },
    {
      name: `${t("analytics:global:cumulativeValue")}`,
      type: ChartLabelType.LINE,
    },
  ];

  const data = {
    series: [
      {
        name: chartLabels[0].name,
        type: "column",
        data: y_axes[1].values,
      },
      {
        name: chartLabels[1].name,
        type: "column",
        data: y_axes[0].values,
      },
      {
        name: chartLabels[2].name,
        type: "line",
        data: y_axes[2].values,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line" as "line",
        stacked: false,
        toolbar: {
          autoSelected: "zoom" as "zoom",
        },
        zoom: {
          enabled: true,
          type: "x" as "x",
          autoScaleYaxis: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          borderRadius: 2,
          borderRadiusWhenStacked: "all" as "all",
          borderRadiusApplication: "around" as "around",
        },
      },
      stroke: {
        width: [1, 1, 1, 2],
        colors: ["", "", AppTheme.palette.primary.main],
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: x_axis,
        tickPlacement: "on",
      },
      yaxis: [
        {
          seriesName: chartLabels[0].name,
          tickAmount: 6,
          opposite: false,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: (value: number) => formatNumber(value),
          },
          title: {
            text: chartLabels[0].name,
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: chartLabels[1].name,
          tickAmount: 6,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: (value: number) => formatNumber(value),
          },
          title: {
            text: chartLabels[1].name,
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: chartLabels[2].name,
          tickAmount: 6,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: (value: number) => formatNumber(value),
          },
          title: {
            text: chartLabels[2].name,
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      colors: [colors[0], colors[1], colors[2]],
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <>
      <ChartLabels chartLabels={chartLabels} colors={colors} />
      <div style={{ marginLeft: "-12px" }}>
        <Chart options={data.options} series={data.series} type="line" height={"370"} />
      </div>
    </>
  );
};
