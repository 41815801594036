import { Box, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

export const StyledCardBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

export const StyledCardHeaderBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
});

export const StyledAuthorTypography = styled(CustomTypography)(({ theme }) => ({
  color: "rgba(0, 0, 0, 0.87)",
  fontWeight: 700,
  letterSpacing: "0.25px",
}));

export const StyledDateTypography = styled(CustomTypography, {
  shouldForwardProp: (prop) => prop !== "resolved",
})<{ resolved?: boolean }>(({ theme, resolved }) => ({
  color: resolved ? theme.palette.text.primary : theme.palette.text.secondary,
  letterSpacing: "0.25px",
}));

export const StyledBodyTypography = styled(CustomTypography, {
  shouldForwardProp: (prop) => prop !== "resolved",
})<{ resolved?: boolean }>(({ theme, resolved }) => ({
  color: resolved ? theme.palette.text.secondary : theme.palette.text.primary,
  letterSpacing: "0.25px",
  whiteSpace: "pre-line",
}));

export const StyledCheckboxContainer = styled(CustomTypography)(({ theme }) => ({
  color: "rgba(0, 0, 0, 0.87)",
  letterSpacing: "0.25px",
  whiteSpace: "pre-line",
}));

export const StyledCardFooterBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
});
