import React, { useEffect, useRef } from "react";
import { AnalyticGraphCard } from "../../analytic-graph-card/analytic-graph-card";
import { OrdersReportPOVolumeChart } from "../orders-report-cumulative-column-chart/orders-report-po-volume-chart";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnalyticFilters,
  selectOrdersExpectedReceiptValueChart,
  selectOrdersItemLatenessChart,
  selectOrdersOnTimeDeliveryChart,
  selectOrdersVolumeChart,
} from "@next/modules/analytics/redux/selectors";
import { POAnalyticsChartTypes, analyticsActions } from "@next/modules/analytics/redux";
import { AnalyticGraphCardLoading } from "../../analytic-graph-card/analytic-graph-card-loading";
import { OrdersReportOnTimeDeliveryChart } from "../orders-report-stacked-column-chart/orders-report-on-time-delivery-chart";
import { getCurrencySuffix } from "@next/utils/miscUtils";
import { formatNumber } from "helpers/formatNumber";
import { OrdersReportItemLatenessChart } from "../orders-report-cumulative-column-chart/orders-report-item-lateness-chart";
import { OrdersReportExpectedReceptionValueChart } from "../orders-report-stacked-column-chart/orders-report-expected-reception-value-chart";

type Props = {};

const OrdersReportChartsSection = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [chartsDataStale, setChartsDataStale] = React.useState({
    ordersVolumeChart: true,
    ordersExpectedReceptionValueChart: true,
    ordersOnTimeDeliveryChart: true,
    ordersItemLatenessChart: true,
  });

  const analyticFilters = useSelector(selectAnalyticFilters);
  const valueSuffix = getCurrencySuffix(analyticFilters?.currency);

  // Charts data
  const ordersVolumeChart = useSelector(selectOrdersVolumeChart);
  const ordersOnTimeDeliveryChart = useSelector(selectOrdersOnTimeDeliveryChart);
  const ordersExpectedReceptionValueChart = useSelector(selectOrdersExpectedReceiptValueChart);
  const ordersItemLatenessChart = useSelector(selectOrdersItemLatenessChart);

  const volumeChartRef = useRef<HTMLDivElement>(null);
  const expectedReceptionValueChartRef = useRef<HTMLDivElement>(null);
  const onTimeDeliveryChartRef = useRef<HTMLDivElement>(null);
  const itemLatenessChartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === volumeChartRef.current && chartsDataStale.ordersVolumeChart) {
            dispatch(
              analyticsActions.fetchOrdersVolumeChartRequest({
                ...analyticFilters,
                chart_type: POAnalyticsChartTypes.VOLUME_CHART,
              })
            );
            setChartsDataStale((prevState) => ({
              ...prevState,
              ordersVolumeChart: false,
            }));
          }

          if (
            entry.target === expectedReceptionValueChartRef.current &&
            chartsDataStale.ordersExpectedReceptionValueChart
          ) {
            dispatch(
              analyticsActions.fetchOrdersExpectedReceiptValueChartRequest({
                ...analyticFilters,
                chart_type: POAnalyticsChartTypes.EXPECTED_RECEIPT_VALUE,
              })
            );
            setChartsDataStale((prevState) => ({
              ...prevState,
              ordersExpectedReceptionValueChart: false,
            }));
          }

          if (
            entry.target === onTimeDeliveryChartRef.current &&
            chartsDataStale.ordersOnTimeDeliveryChart
          ) {
            dispatch(
              analyticsActions.fetchOrdersOnTimeDeliveryChartRequest({
                ...analyticFilters,
                chart_type: POAnalyticsChartTypes.ON_TIME_DELIVERY,
              })
            );
            setChartsDataStale((prevState) => ({
              ...prevState,
              ordersOnTimeDeliveryChart: false,
            }));
          }

          if (
            entry.target === itemLatenessChartRef.current &&
            chartsDataStale.ordersItemLatenessChart
          ) {
            dispatch(
              analyticsActions.fetchOrdersItemLatenessChartRequest({
                ...analyticFilters,
                chart_type: POAnalyticsChartTypes.ITEM_LATENESS,
              })
            );
            setChartsDataStale((prevState) => ({
              ...prevState,
              ordersItemLatenessChart: false,
            }));
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    if (volumeChartRef.current) {
      observer.observe(volumeChartRef.current);
    }

    if (expectedReceptionValueChartRef.current) {
      observer.observe(expectedReceptionValueChartRef.current);
    }

    if (onTimeDeliveryChartRef.current) {
      observer.observe(onTimeDeliveryChartRef.current);
    }

    if (itemLatenessChartRef.current) {
      observer.observe(itemLatenessChartRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [chartsDataStale, analyticFilters, dispatch]);

  useEffect(() => {
    setChartsDataStale({
      ordersVolumeChart: true,
      ordersExpectedReceptionValueChart: true,
      ordersOnTimeDeliveryChart: true,
      ordersItemLatenessChart: true,
    });
  }, [analyticFilters]);

  return (
    <Grid item xs={12}>
      <div ref={volumeChartRef}>
        {ordersVolumeChart && !chartsDataStale.ordersVolumeChart ? (
          <AnalyticGraphCard
            content={{
              mainTitle: t("analytics:ordersReport:charts:volume:title"),
              cardContents: [
                {
                  title: t("analytics:ordersReport:charts:volume:itemsSent"),
                  titleTooltip: t("analytics:ordersReport:charts:volume:itemsSentTooltip"),
                  boldTypographyContent: `${ordersVolumeChart?.total_items_sent}`,
                },
                {
                  title: t("analytics:ordersReport:charts:volume:totalValue", {
                    currency: analyticFilters?.currency,
                  }),
                  titleTooltip: t("analytics:ordersReport:charts:volume:totalValueTooltip"),
                  boldTypographyContent: `${formatNumber(ordersVolumeChart?.commulative_value)} ${valueSuffix}`,
                },
              ],
            }}
            GraphComponent={
              <OrdersReportPOVolumeChart
                id={ordersVolumeChart.id}
                x_axis={ordersVolumeChart.x_axis}
                y_axes={ordersVolumeChart.y_axes}
              />
            }
          />
        ) : (
          <AnalyticGraphCardLoading />
        )}
      </div>

      <div ref={expectedReceptionValueChartRef}>
        {ordersExpectedReceptionValueChart && !chartsDataStale.ordersExpectedReceptionValueChart ? (
          <AnalyticGraphCard
            content={{
              mainTitle: t("analytics:ordersReport:charts:expectedReceptionValue:title", {
                currency: analyticFilters?.currency,
              }),
              cardContents: [
                {
                  title: t("analytics:ordersReport:charts:expectedReceptionValue:valueReceived", {
                    currency: analyticFilters?.currency,
                  }),
                  titleTooltip: t(
                    "analytics:ordersReport:charts:expectedReceptionValue:valueReceivedTooltip"
                  ),
                  boldTypographyContent: `${formatNumber(ordersExpectedReceptionValueChart?.cumulative?.received)} ${valueSuffix}`,
                },
                {
                  title: t("analytics:ordersReport:charts:expectedReceptionValue:valueExpected", {
                    currency: analyticFilters?.currency,
                  }),
                  titleTooltip: t(
                    "analytics:ordersReport:charts:expectedReceptionValue:valueExpectedTooltip"
                  ),
                  boldTypographyContent: `${formatNumber(ordersExpectedReceptionValueChart?.cumulative?.expected)} ${valueSuffix}`,
                },
              ],
            }}
            GraphComponent={
              <OrdersReportExpectedReceptionValueChart
                id={ordersExpectedReceptionValueChart.id}
                x_axis={ordersExpectedReceptionValueChart.x_axis}
                y_axes={ordersExpectedReceptionValueChart.y_axes}
              />
            }
          />
        ) : (
          <AnalyticGraphCardLoading />
        )}
      </div>

      <div ref={onTimeDeliveryChartRef}>
        {ordersOnTimeDeliveryChart && !chartsDataStale.ordersOnTimeDeliveryChart ? (
          <Grid item xs={12}>
            <AnalyticGraphCard
              content={{
                mainTitle: t("analytics:ordersReport:charts:shipment:title"),
                description: t("analytics:ordersReport:charts:shipment:description"),
                cardContents: [
                  {
                    title: t("analytics:ordersReport:charts:shipment:cards:onTime"),
                    titleTooltip: t("analytics:ordersReport:charts:shipment:cards:onTimeTooltip"),
                    boldTypographyContent: `${formatNumber(ordersOnTimeDeliveryChart?.on_time_percentage)}%`,
                    smallTypographyContent: `${ordersOnTimeDeliveryChart?.total_on_time_count} ${t(
                      "analytics:global:item",
                      {
                        count: ordersOnTimeDeliveryChart?.total_on_time_count,
                      }
                    )}`,
                  },
                  {
                    title: t("analytics:ordersReport:charts:shipment:cards:late"),
                    titleTooltip: t("analytics:ordersReport:charts:shipment:cards:lateTooltip"),
                    boldTypographyContent: `${formatNumber(ordersOnTimeDeliveryChart?.late_percentage)}%`,
                    smallTypographyContent: `${ordersOnTimeDeliveryChart?.total_late_count} ${t(
                      "analytics:global:item",
                      {
                        count: ordersOnTimeDeliveryChart?.total_late_count,
                      }
                    )}`,
                  },
                ],
              }}
              GraphComponent={
                <OrdersReportOnTimeDeliveryChart
                  id={ordersOnTimeDeliveryChart.id}
                  x_axis={ordersOnTimeDeliveryChart.x_axis}
                  y_axes={ordersOnTimeDeliveryChart.y_axes}
                />
              }
            />
          </Grid>
        ) : (
          <AnalyticGraphCardLoading />
        )}
      </div>

      <div ref={itemLatenessChartRef}>
        {ordersItemLatenessChart && !chartsDataStale.ordersItemLatenessChart ? (
          <AnalyticGraphCard
            content={{
              mainTitle: t("analytics:ordersReport:charts:itemLateness:title"),
              cardContents: [
                {
                  title: t("analytics:ordersReport:charts:itemLateness:lateItemsCount"),
                  titleTooltip: t(
                    "analytics:ordersReport:charts:itemLateness:lateItemsCountTooltip"
                  ),
                  boldTypographyContent: `${formatNumber(ordersItemLatenessChart?.total_late_count)}`,
                },
                {
                  title: t("analytics:ordersReport:charts:itemLateness:lateItemsValue"),
                  titleTooltip: t(
                    "analytics:ordersReport:charts:itemLateness:lateItemsValueTooltip"
                  ),
                  boldTypographyContent: `${formatNumber(ordersItemLatenessChart?.total_late_value)} ${valueSuffix}`,
                },
              ],
            }}
            GraphComponent={
              <OrdersReportItemLatenessChart
                id={ordersItemLatenessChart.id}
                x_axis={ordersItemLatenessChart.x_axis}
                y_axes={ordersItemLatenessChart.y_axes}
              />
            }
          />
        ) : (
          <AnalyticGraphCardLoading />
        )}
      </div>
    </Grid>
  );
};

export default OrdersReportChartsSection;
