import React from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { ReadinessDashboardSupplierViewTotalCard } from "./readiness-dashboard-supplier-view-total-card/readiness-dashboard-supplier-view-total-card";
import { selectReadinessDashboardData } from "@next/modules/vendor-management/redux/selectors";
import {
  SupplierViewTotalCard,
  SupplierViewTotalCardSlugs,
} from "@next/modules/vendor-management/redux";

export const ReadinessDashboardSupplierViewTotalCardsGroup: React.FC = () => {
  const dashboardData = useSelector(selectReadinessDashboardData);
  const totalData: SupplierViewTotalCard = dashboardData?.supplier_view;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container sx={{ width: "100%" }} spacing={2}>
        {Object.entries(SupplierViewTotalCardSlugs).map(([key, title]) => {
          const value = totalData?.[key as keyof SupplierViewTotalCard];
          return (
            <Grid item xs={6} md={6} lg={3} key={key}>
              <ReadinessDashboardSupplierViewTotalCard title={title} value={value} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
