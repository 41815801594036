import React from "react";
import Chart from "react-apexcharts";
import { formatNumber } from "@next/modules/vendor-management/utils/formatNumber";
import { getPercentage } from "@next/modules/vendor-management/utils/getPercentage";
import {
  RadialBarChart as RadialBarChartProps,
  RadialBarChartSlugs,
} from "@next/modules/vendor-management/redux";
import * as S from "./radial-bar-chart.styled";

type Props = {} & RadialBarChartProps;

export const RadialBarChart: React.FC<Props> = ({ slug, current_value, criteria_value }) => {
  const value = Number(getPercentage(current_value, criteria_value));
  const isValueValid = value > 20;
  const chartColor = isValueValid ? "#242D66" : "#FF3E32";

  const chartData = {
    series: [value],
    options: {
      chart: {
        type: "radialBar" as const,
        offsetY: -10,
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          hollow: {
            size: "60%",
          },
          track: {
            background: "#F0F7FB",
            strokeWidth: "100%",
            margin: 0,
          },
          dataLabels: {
            show: false,
          },
        },
      },
      fill: {
        colors: [chartColor],
      },
      stroke: {
        dashArray: 4,
      },
    },
  };

  return (
    <S.StyledBox>
      <Chart
        options={chartData.options}
        series={chartData.series}
        width={"394px"}
        height={"404px"}
        type="radialBar"
      />

      <S.StyledContentBox>
        <S.StyledTopTypography isValueValid={isValueValid} variant="h4">
          {formatNumber(current_value)}
        </S.StyledTopTypography>

        <S.StyledBottomTypography variant="h4">
          {formatNumber(criteria_value)}
        </S.StyledBottomTypography>

        <S.StyledNameTypography variant="body2">{RadialBarChartSlugs[slug]}</S.StyledNameTypography>
      </S.StyledContentBox>
    </S.StyledBox>
  );
};
