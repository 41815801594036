import React from "react";
import { GridAlignment, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { NameCell } from "../../report-table-cells/name-cell";
import { TextCell } from "../../report-table-cells/text-cell";
import { HeaderCell } from "../../report-table-cells/header-cell";
import { formatNumber } from "helpers/formatNumber";
import i18next from "i18next";
import { TextCellWithTrend } from "../../report-table-cells/text-cell-with-trend";
import { formatToHoursWhenLessThanADay } from "@next/utils/dateUtils";

export const getOrdersReportSupplierDetailsTableColumns = (
  t: i18next.TFunction,
  valueSuffix: string
) => {
  const commonColumnProperties = {
    sortable: true,
    align: "center" as GridAlignment,
    headerAlign: "center" as GridAlignment,
  };

  const columns: GridColDef[] = [
    {
      field: "supplier_name",
      headerName: "Name",
      renderHeader: () => <HeaderCell text={t("analytics:global:name")} />,
      width: 246,
      sortable: true,
      renderCell: NameCell,
    },
    {
      field: "items_count",
      headerName: "Items sent in order",
      renderHeader: () => (
        <HeaderCell
          text={t("analytics:ordersReport:table:supplierDetails:columns:itemsReceived:text")}
          tooltipTitle={t(
            "analytics:ordersReport:table:supplierDetails:columns:itemsReceived:tooltipTitle"
          )}
        />
      ),
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <TextCell {...params} value={`${params.value} ${t("analytics:global:items")}`} />
      ),
      valueGetter: (params) => params.value,
      ...commonColumnProperties,
    },
    {
      field: "total_value",
      headerName: "Total value (CAD)",
      renderHeader: () => (
        <HeaderCell
          text={t(
            "analytics:ordersReport:table:supplierDetails:columns:purchaseValueReceived:text"
          )}
          tooltipTitle={t(
            "analytics:ordersReport:table:supplierDetails:columns:purchaseValueReceived:tooltipTitle"
          )}
        />
      ),
      width: 250,
      renderCell: (params: GridRenderCellParams) => (
        <TextCellWithTrend
          {...params}
          value={`${formatNumber(params.value)} ${valueSuffix}`}
          trendPercentage={params.row.item_recieved_trend.trend_value}
          trendType={params.row.item_recieved_trend.trend_type}
          trendColorForUp="primary"
          trendColorForDown="primary"
        />
      ),
      valueGetter: (params) => params.value,
      ...commonColumnProperties,
    },
    {
      field: "avg_time_to_acknowledge",
      headerName: "Avg. time to acknowledge",
      renderHeader: () => (
        <HeaderCell
          text={t("analytics:ordersReport:table:supplierDetails:columns:ackTime:text")}
          tooltipTitle={t(
            "analytics:ordersReport:table:supplierDetails:columns:ackTime:tooltipTitle"
          )}
        />
      ),
      width: 300,
      renderCell: (params: GridRenderCellParams) => (
        <TextCell {...params} value={formatToHoursWhenLessThanADay(params.value)} />
      ),
      valueGetter: (params) => params.value,
      ...commonColumnProperties,
    },
    {
      field: "date_change_percentage",
      headerName: "Date change rate",
      renderHeader: () => (
        <HeaderCell
          text={t("analytics:ordersReport:table:supplierDetails:columns:dateChangeRate:text")}
          tooltipTitle={t(
            "analytics:ordersReport:table:supplierDetails:columns:dateChangeRate:tooltipTitle"
          )}
        />
      ),
      width: 300,
      renderCell: (params: GridRenderCellParams) => (
        <TextCell {...params} value={`${params.value}%`} />
      ),
      valueGetter: (params) => params.value,
      ...commonColumnProperties,
    },
    {
      field: "price_change_percentage",
      headerName: "Price change rate",
      renderHeader: () => (
        <HeaderCell
          text={t("analytics:ordersReport:table:supplierDetails:columns:priceChangeRate:text")}
          tooltipTitle={t(
            "analytics:ordersReport:table:supplierDetails:columns:priceChangeRate:tooltipTitle"
          )}
        />
      ),
      width: 300,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <TextCell {...params} value={`${params.value}%`} />
      ),
      ...commonColumnProperties,
    },
    {
      field: "on_time_percentage",
      headerName: "On time delivery",
      renderHeader: () => (
        <HeaderCell
          text={t("analytics:ordersReport:table:supplierDetails:columns:delivery:text")}
          tooltipTitle={t(
            "analytics:ordersReport:table:supplierDetails:columns:delivery:tooltipTitle"
          )}
        />
      ),
      width: 300,
      renderCell: (params: GridRenderCellParams) => (
        <TextCellWithTrend
          {...params}
          value={`${formatNumber(params.value)} %`}
          trendPercentage={params.row.item_recieved_trend.trend_value}
          trendType={params.row.item_recieved_trend.trend_type}
          trendColorForUp="success"
          trendColorForDown="error"
        />
      ),
      valueGetter: (params) => params.value,
      ...commonColumnProperties,
    },
    {
      field: "avg_lateness",
      headerName: "Avg. lateness",
      renderHeader: () => (
        <HeaderCell
          text={t("analytics:ordersReport:table:supplierDetails:columns:avgLateness:text")}
          tooltipTitle={t(
            "analytics:ordersReport:table:supplierDetails:columns:avgLateness:tooltipTitle"
          )}
        />
      ),
      width: 220,
      renderCell: (params: GridRenderCellParams) => (
        <TextCell
          {...params}
          value={`${params.value} ${t("analytics:global:day", {
            count: params.value,
          })}`}
        />
      ),
      valueGetter: (params) => params.value,
      ...commonColumnProperties,
    },
  ];

  return columns;
};
