import { Box, styled } from "@mui/material";
import Chart from "react-apexcharts";

export const StyledBox = styled(Box)({
  width: "100%",
  height: "122px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "24px",
  position: "relative",
});

export const LegendWrapper = styled(Box)({
  position: "absolute",
  top: "104px",
  "@media (max-width: 1800px) and (min-width: 1200px)": {
    top: "90px",
  },
  "@media (max-width: 988px) and (min-width: 900px)": {
    top: "90px",
  },
  "@media (max-width: 675px)": {
    top: "90px",
  },
});

export const StyledChart = styled(Chart)({
  width: "160px",
  height: "150px",
  marginTop: "-5px",
  "@media (max-width: 1800px) and (min-width: 1200px)": {
    marginTop: "-10px",
  },
  "@media (max-width: 988px) and (min-width: 900px)": {
    marginTop: "-10px",
  },
  "@media (max-width: 675px)": {
    marginTop: "-10px",
  },
});
