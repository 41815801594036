import React from "react";
import * as S from "./readiness-dashboard-supplier-view-total-card.styled";

type Props = {
  title: string;
  value: string | number;
};

export const ReadinessDashboardSupplierViewTotalCard: React.FC<Props> = ({ title, value }) => {
  return (
    <S.StyledBox>
      <S.TitleTypography variant="h6">{title}</S.TitleTypography>

      <S.TextTypography>{value}</S.TextTypography>
    </S.StyledBox>
  );
};
