import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OrdersReportTable as OrdersReportTableType } from "@next/modules/analytics/redux";
import { getOrdersReportTableColumns } from "./orders-report-team-workload-table.columns";
import * as S from "./orders-report-team-workload-table.styled";

export const OrdersReportTeamWorkloadTable: React.FC<OrdersReportTableType> = ({ id, rows }) => {
  const { t } = useTranslation();
  const columns = getOrdersReportTableColumns(t);

  return (
    <S.RootBox>
      <Box width="95%" pb="24px">
        <S.StyledDiv>
          <S.StyledTypography variant="h6">
            {t("analytics:ordersReport:table:teamWorkload:title")}
          </S.StyledTypography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <S.StyledTypography variant="body1">
              {t("analytics:ordersReport:table:teamWorkload:activeUsers")}
            </S.StyledTypography>
            <S.StyledTypography fontFamily={"Space Grotesk"} variant="h4">
              {rows?.length || 0}
            </S.StyledTypography>
          </Box>
        </S.StyledDiv>

        <S.StyledDataGridPro
          rows={rows}
          columns={columns}
          disableSelectionOnClick={true}
          disableColumnResize
          headerHeight={44}
          rowHeight={60}
          hideFooter
          getRowId={(row) => row.full_name}
        />
      </Box>
    </S.RootBox>
  );
};
