import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  OrdersReportSupplierDetailsTableRow,
  OrdersReportTable as OrdersReportTableType,
} from "@next/modules/analytics/redux";
import { getOrdersReportSupplierDetailsTableColumns } from "./orders-report-supplier-details-table.columns";
import * as S from "./orders-report-supplier-details-table.styled";
import { useSelector } from "react-redux";
import { selectAnalyticFilters } from "@next/modules/analytics/redux/selectors";
import { CURRENCY_OPTIONS } from "@next/utils/countryList";

export const OrdersReportSupplierDetailsTable: React.FC<OrdersReportTableType> = ({ id, rows }) => {
  const { t } = useTranslation();
  const analyticFilters = useSelector(selectAnalyticFilters);
  const valueSuffix =
    analyticFilters?.currency === CURRENCY_OPTIONS.USD ||
    analyticFilters?.currency === CURRENCY_OPTIONS.CAD
      ? "$"
      : "€";
  const columns = getOrdersReportSupplierDetailsTableColumns(t, valueSuffix);

  return (
    <S.RootBox>
      <Box width="95%" pb="24px">
        <S.StyledDiv>
          <S.StyledTypography variant="h6">
            {t("analytics:ordersReport:table:supplierDetails:title")}
          </S.StyledTypography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <S.StyledTypography variant="body1">
              {t("analytics:ordersReport:table:supplierDetails:supplierCount")}
            </S.StyledTypography>
            <S.StyledTypography fontFamily={"Space Grotesk"} variant="h4">
              {rows?.length || 0}
            </S.StyledTypography>
          </Box>
        </S.StyledDiv>

        <S.StyledDataGridPro
          rows={rows as OrdersReportSupplierDetailsTableRow[]}
          columns={columns}
          disableSelectionOnClick={true}
          disableColumnResize
          headerHeight={44}
          rowHeight={60}
          hideFooter
        />
      </Box>
    </S.RootBox>
  );
};
