import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { AnalyticCard } from "../components/analytic-card/analytic-card";
import { styled } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { OrdersReportSupplierDetailsTable } from "../components/orders-report/orders-report-supplier-details-table/orders-report-supplier-details-table";
import {
  selectAnalyticFilters,
  selectOrdersReport,
  selectOrdersReportLoading,
} from "../redux/selectors";
import { analyticsActions } from "../redux";
import { formatNumber } from "helpers/formatNumber";
import { AnalyticFilters } from "../components/analytic-filters/analytic-filters";
import { LoadingScreen } from "../components/loading-screen/loading-screen";
import { useTranslation } from "react-i18next";
import { OrdersReportTeamWorkloadTable } from "../components/orders-report/orders-report-team-worload-table/orders-report-team-workload-table";
import { formatToHoursWhenLessThanADay } from "@next/utils/dateUtils";
import { getCurrencySuffix } from "@next/utils/miscUtils";
import OrdersReportChartsSection from "../components/orders-report/orders-report-charts-section/orders-report-charts-section";

const RootDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

export const OrdersReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ordersReport = useSelector(selectOrdersReport);
  const ordersReportLoading = useSelector(selectOrdersReportLoading);
  const analyticFilters = useSelector(selectAnalyticFilters);
  const valueSuffix = getCurrencySuffix(analyticFilters?.currency);

  useEffect(() => {
    dispatch(analyticsActions.fetchOrdersReportRequest(analyticFilters));
  }, [analyticFilters]);

  if (ordersReportLoading || !ordersReport) {
    return <LoadingScreen />;
  }

  return (
    <RootDiv>
      <AnalyticFilters isInSupplierReport={false} isInOrderReport />

      <Box p="36px" pt="24px" sx={{ marginTop: "24px", width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:valueSent:text")}
                tooltipTitle={t("analytics:ordersReport:cards:valueSent:tooltipTitle")}
                value={`${formatNumber(ordersReport.total_value)} ${valueSuffix}`}
                smallLabel={t("analytics:global:lines", {
                  count: ordersReport.items_count,
                })}
                trendPercentage={ordersReport.total_value_trend.trend_value}
                trendType={ordersReport.total_value_trend.trend}
                trendColorForUp="primary"
                trendColorForDown="primary"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:valueReceived:text")}
                tooltipTitle={t("analytics:ordersReport:cards:valueReceived:tooltipTitle")}
                value={`${formatNumber(ordersReport.received_value)} ${valueSuffix}`}
                smallLabel={t("analytics:global:lines", {
                  count: ordersReport.received_item_count,
                })}
                trendPercentage={ordersReport.received_value_trend.trend_value}
                trendType={ordersReport.received_value_trend.trend}
                trendColorForUp="primary"
                trendColorForDown="primary"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:valueCurrentlyLate:text")}
                tooltipTitle={t("analytics:ordersReport:cards:valueCurrentlyLate:tooltipTitle")}
                value={`${formatNumber(ordersReport.late_value)} ${valueSuffix}`}
                smallLabel={t("analytics:global:lines", {
                  count: ordersReport.late_count,
                })}
                trendPercentage={ordersReport.late_value_trend.trend_value}
                trendType={ordersReport.late_value_trend.trend}
                trendColorForUp="error"
                trendColorForDown="success"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:valueNextMonth:text")}
                tooltipTitle={t("analytics:ordersReport:cards:valueNextMonth:tooltipTitle")}
                value={`${formatNumber(ordersReport.expected_value.next_month_expected_value)} ${valueSuffix}`}
                smallLabel={t("analytics:global:lines", {
                  count: ordersReport.expected_value.next_month_item_count,
                })}
                trendPercentage={ordersReport.expected_trend_value?.trend_value}
                trendType={ordersReport.expected_trend_value?.trend}
                trendColorForUp="primary"
                trendColorForDown="primary"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:acknowledmentTime:text")}
                tooltipTitle={t("analytics:ordersReport:cards:acknowledmentTime:tooltipTitle")}
                value={formatToHoursWhenLessThanADay(ordersReport.avg_acknowledgment_time)}
                trendPercentage={ordersReport.avg_acknowledgment_time_trend.trend_value}
                trendType={ordersReport.avg_acknowledgment_time_trend.trend}
                trendColorForUp="error"
                trendColorForDown="success"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:dateChangeRate:text")}
                tooltipTitle={t("analytics:ordersReport:cards:dateChangeRate:tooltipTitle")}
                value={ordersReport.delivery_date_change_rate + "%"}
                trendPercentage={ordersReport.delivery_date_change_rate_trend.trend_value}
                trendType={ordersReport.delivery_date_change_rate_trend.trend}
                trendColorForUp="error"
                trendColorForDown="success"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:priceChangeRate:text")}
                tooltipTitle={t("analytics:ordersReport:cards:priceChangeRate:tooltipTitle")}
                value={ordersReport.price_change_rate + "%"}
                trendPercentage={ordersReport.price_change_percentage_trend.trend_value}
                trendType={ordersReport.price_change_percentage_trend.trend}
                trendColorForUp="error"
                trendColorForDown="success"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:onTimeDelivery:text")}
                tooltipTitle={t("analytics:ordersReport:cards:onTimeDelivery:tooltipTitle")}
                value={ordersReport.on_time_delivery_percentage + "%"}
                trendPercentage={ordersReport.on_time_delivery_percentage_trend.trend_value}
                trendType={ordersReport.on_time_delivery_percentage_trend.trend}
                trendColorForUp="success"
                trendColorForDown="error"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:averageLateness:text")}
                tooltipTitle={t("analytics:ordersReport:cards:averageLateness:tooltipTitle")}
                value={formatToHoursWhenLessThanADay(ordersReport.avg_lateness)}
                trendPercentage={ordersReport.avg_lateness_trend.trend_value}
                trendType={ordersReport.avg_lateness_trend.trend}
                trendColorForUp="error"
                trendColorForDown="success"
              />
            </Grid>

            <Grid item xs={12}>
              <OrdersReportSupplierDetailsTable
                id={ordersReport.tables[0].id}
                rows={ordersReport.tables[0].rows}
              />
            </Grid>

            <Grid item xs={12}>
              <OrdersReportTeamWorkloadTable
                id={ordersReport.tables[1].id}
                rows={ordersReport.tables[1].rows}
              />
            </Grid>

            <OrdersReportChartsSection />

            {/* 

            <Grid item xs={12}>
              <AnalyticGraphCard
                content={{
                  mainTitle: t("analytics:ordersReport:charts:shipment:title"),
                  description: t("analytics:ordersReport:charts:shipment:description"),
                  cardContents: [
                    {
                      title: t("analytics:ordersReport:charts:shipment:cards:onTime"),
                      boldTypographyContent: `${ordersReport?.on_time_delivery_percentage}%`,
                      smallTypographyContent: `${
                        ordersReport?.on_time_count
                      } ${t("analytics:global:items")}`,
                    },
                    {
                      title: t("analytics:ordersReport:charts:shipment:cards:late"),
                      boldTypographyContent: `${100 - ordersReport?.on_time_delivery_percentage}%`,
                      smallTypographyContent: `${ordersReport?.late_count} ${t(
                        "analytics:global:items"
                      )}`,
                    },
                  ],
                }}
                GraphComponent={
                  <OrdersReportStackedColumnChart
                    id={ordersReport.charts[1].id}
                    x_axis={ordersReport.charts[1].x_axis}
                    y_axes={ordersReport.charts[1].y_axes}
                  />
                }
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Box>
    </RootDiv>
  );
};
