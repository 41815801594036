import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress, Typography } from "@mui/material";
import { vendorManagementActions } from "@next/modules/vendor-management/redux";
import {
  selectInternalNotes,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  StyledBorderedBox,
  StyledButton,
  StyledCenteredBox,
  StyledContentBox,
  StyledNotesInput,
} from "./side-drawer-internal-note.styled";
import snackbarUtils from "@next/utils/snackbarUtils";
import { useKeyBoardEventHandler } from "@next/hooks/useKeyBoardEventHandler";
import { formatDateAndTime } from "helpers/formatDate";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";

type Props = {};

export const SideDrawerInternalNote: FC<Props> = () => {
  // utils
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();

  // state
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeStep = steps[activeFormStepIndex];
  const {
    data: { notes: savedTextContent = "", notes_last_updated_at: lastSavedDate = "" },
    fetchIsLoading,
    updateIsLoading,
  } = useSelector(selectInternalNotes);
  const [isEditMode, setIsEditMode] = useState(false);
  const [noteText, setNoteText] = useState("");

  // computed
  const isEmpty = !Boolean(savedTextContent);
  const isLoading = fetchIsLoading || updateIsLoading;

  // handlers
  const handleEditBtnClick = () => {
    setIsEditMode(true);
  };

  const handleSaveBtnClick = () => {
    setIsEditMode(false);
    dispatch(
      vendorManagementActions.updateInternalNote({
        notes: noteText,
        companyId,
        step: activeStep?.step,
        onSuccess: () => {
          snackbarUtils.toast("Note saved");
        },
      })
    );
  };

  const { handleKeysPressEvent } = useKeyBoardEventHandler({
    keyboardKeys: ["Enter"],
    onKeyEvent: () => {
      handleSaveBtnClick();
    },
  });

  const handleInitNotesText = useCallback(
    () => setNoteText(savedTextContent || ""),
    [savedTextContent]
  );

  // listeners
  useEffect(handleInitNotesText, [handleInitNotesText]);

  // render
  const editBtnRender = (
    <StyledButton
      startIcon={<EditIcon />}
      variant="contained"
      color="primary"
      onClick={handleEditBtnClick}
      disableElevation
      disabled={false}
    >
      <Typography variant="body2">Edit note</Typography>
    </StyledButton>
  );

  const saveBtnRender = (
    <StyledButton
      startIcon={<CheckIcon />}
      variant="contained"
      color="primary"
      onClick={handleSaveBtnClick}
      disableElevation
    >
      <Typography variant="body2">Save note</Typography>
    </StyledButton>
  );

  const noteInputRender = (
    <StyledNotesInput
      autoFocus
      onKeyDown={handleKeysPressEvent}
      value={noteText}
      onChange={(e) => setNoteText(e.target.value)}
    />
  );

  const emptyContentRender = (
    <StyledCenteredBox>
      <Typography variant="body2" color="GrayText" fontStyle="italic">
        {"No notes yet, click “Edit note” to start your note"}
      </Typography>
    </StyledCenteredBox>
  );

  const noteContentRender = (
    <StyledContentBox>
      <Typography variant="body1" mb="12px">
        {savedTextContent}
      </Typography>
      {lastSavedDate ? (
        <Typography variant="body2" color="GrayText">
          {"Last saved " + formatDateAndTime(lastSavedDate, "MMM DD, YYYY, ")}
        </Typography>
      ) : null}
    </StyledContentBox>
  );

  if (isLoading) {
    return (
      <StyledCenteredBox>
        <CircularProgress />
      </StyledCenteredBox>
    );
  }

  return (
    <>
      <StyledBorderedBox>{isEditMode ? saveBtnRender : editBtnRender}</StyledBorderedBox>

      {isEditMode ? noteInputRender : isEmpty ? emptyContentRender : noteContentRender}
    </>
  );
};
