import i18n from "assets/configi18n/i18n";
import moment from "moment-business-days";

const t = i18n.t.bind(i18n);

export const calculateBusinessDays = (endDate?: string) => {
  if (!endDate) return null;

  var diff = moment(endDate, "YYYY-MM-DD").businessDiff(moment());

  return diff;
};

export const findNextBusinessDay = () => {
  const startDate = moment();

  const nextBusinessDay = moment(startDate).nextBusinessDay();

  return nextBusinessDay;
};

export function formatToHoursWhenLessThanADay(input: any, setSuffix = true): string {
  const value = parseFloat(input);

  if (isNaN(value) || value < 0) {
    return "";
  }

  // If the value is between 0.1 and 0.9 (inclusive), convert to hours
  if (value > 0 && value < 1) {
    const hours = value * 24;
    if (setSuffix) {
      return `${parseFloat(hours.toFixed(2))}${t("common:hour")}`;
    } else return parseFloat(hours.toFixed(2)).toString();
  }
  // For values >= 1, return as-is with two decimal places
  return `${parseFloat(value.toFixed(2))} ${t("common:day", {
    count: value,
  })}`;
}
