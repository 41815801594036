import React from "react";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";
import {
  selectIsAllStepsApprovalNull,
  selectIsAllStepsCompleted,
  selectMessagesOfThread,
} from "@next/modules/vendor-management/redux/selectors";
import { SideDrawerMessageCard } from "./side-drawer-message-card/side-drawer-message-card";
import { formatDateAndTime } from "helpers/formatDate";
import { getUser } from "services/profile/profile.selectors";

export const SideDrawerMessageThread = () => {
  const messagesOfThread = useSelector(selectMessagesOfThread);
  const user = useSelector(getUser);
  const userType = user?.user_type;
  const isAllStepsCompleted = useSelector(selectIsAllStepsCompleted);
  const isAllStepsApprovalNull = useSelector(selectIsAllStepsApprovalNull);
  const isReadOnly = !isAllStepsCompleted && isAllStepsApprovalNull;

  return (
    <>
      {messagesOfThread?.length > 0
        ? messagesOfThread.map((message, i) => (
            <React.Fragment key={message.id}>
              <SideDrawerMessageCard
                id={message.id}
                body={message.body}
                author={message.author}
                createdAt={formatDateAndTime(message.created_at, "MMM D, YYYY", "h:mm a")}
                resolvedAt={
                  message?.resolved_at
                    ? formatDateAndTime(message.resolved_at, "MMM D, YYYY", "h:mm a")
                    : null
                }
                allowToMarkAsResolved={
                  message.is_supplier_message && userType === "approver" && !isReadOnly
                }
              />

              {i !== messagesOfThread.length - 1 ? <Divider /> : null}
            </React.Fragment>
          ))
        : null}
    </>
  );
};
