import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { CustomFileObject } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload.types";
import mime from "mime";
import { PartUpload } from "@next/modules/project/redux";
import { FileUploadProgress } from "@next/modules/workspace/redux";

let cachedIpElem: null | HTMLElement = null;

export const createFileUrlFromObject = (file: File) => {
  try {
    return URL.createObjectURL(file);
  } catch (error) {
    console.error("Error creating file url", error);
    return "";
  }
};

export const convertFilesToObjects = (
  files: (File | FileData)[],
  filesUploadProgress?: FileUploadProgress[] | undefined
): FileData[] => {
  return files?.map((file) => {
    if (!(file instanceof File)) return file;
    const extensionFromName = file?.name?.split(".")?.pop();

    return {
      file_name: removeExtenstionFromFileName(file?.name),
      file_extension: extensionFromName || file.type?.replace(/(.*)\//g, ""),
      file_size: file?.size,
      file_url: createFileUrlFromObject(file),
      file_uploaded: getFileUploadProgress(filesUploadProgress, file?.name),
    };
  });
};

export const convertFileUploadProgresstoPartUpload = (file: FileUploadProgress): PartUpload => {
  const extensionFromName = file?.fileName.split(".").pop();
  return {
    pk: -1,
    file_extension: extensionFromName || file.fileType?.replace(/(.*)\//g, "") || "",
    file_name: removeExtenstionFromFileName(file.fileName),
    file_size: file.total,
    file_url: "",
    file_uploaded: file.percent,
  };
};
export const getFileUploadProgress = (
  filesUploadProgress: FileUploadProgress[] | undefined,
  fileName: string
): number | undefined => {
  if (!fileName) return; // Null check for file name
  const cleanFileName = removeExtenstionFromFileName(fileName);
  if (filesUploadProgress) {
    const progress = filesUploadProgress.find(
      (item) => removeExtenstionFromFileName(item.fileName) === cleanFileName
    );
    return progress?.percent;
  }
};

export const convertObjectToFiles = (filesData: FileData[]): CustomFileObject[] => {
  return filesData?.map((fileData) => {
    const file = new File([""], `${fileData.file_name}.${fileData.file_extension}`);

    return { file, pk: fileData.pk };
  });
};

export const convertObjectToFilesWithoutKey = (filesData: FileData[]): File[] => {
  return filesData?.map((fileData) => {
    const file = new File([""], `${fileData.file_name}.${fileData.file_extension}`);

    return file;
  });
};

export const removeExtenstionFromFileName = (fileName: string) => {
  return fileName?.replace(/\.[^/.]+$/, "");
};

export const downloadFileNext = (
  blob: Blob,
  fileName: string,
  defaultExtension?: string,
  onFinish?: () => void
) => {
  try {
    const isExtensionExistInFileName = /(?:\.([^.]+))?$/.test(fileName);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    if (defaultExtension) {
      link.download = `${fileName}.${defaultExtension}`;
    } else if (isExtensionExistInFileName) {
      link.download = fileName;
    } else {
      let fileExtension = mime.getExtension(blob.type);

      if (blob?.type === "application/x-zip-compressed") {
        fileExtension = mime.getExtension("application/zip");
      }

      link.download = `${fileName}.${fileExtension}`;
    }

    link.click();
    onFinish && onFinish();
  } catch (error) {
    console.error("Error downloading file", error);
  }
};

export const downloadFileUrl = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.click();
};

export function pickFiles({
  accept,
  multiple = true,
  maxMBs = 10,
  onChange,
}: {
  accept?: string;
  multiple?: boolean;
  maxMBs?: number;
  onChange?: (files: File[]) => void;
}) {
  if (!cachedIpElem) cachedIpElem = document.getElementById("c-global-file-input");
  if (!cachedIpElem) return;

  if (accept) cachedIpElem.setAttribute("accept", accept);
  if (multiple !== undefined) cachedIpElem.setAttribute("multiple", multiple + "");

  if (typeof onChange === "function") {
    cachedIpElem.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      if (!target.files) return;

      const files = [];
      for (const file of target.files) {
        // if (maxMBs && !isNaN(maxMBs) && file.size / 1024 / 1024 > maxMBs) {
        //   snackbarService.showSnackbar(
        //     t("workspaceNext:fileSizeExceeds"),
        //     "error"
        //   );
        //   continue;
        // }
        files.push(file as never);
      }

      if (files.length) onChange(files);

      //reset the value to have onchane trigger even if same file selected in succession
      target.value = "";
    };
  }

  cachedIpElem.click();
}

export async function base64ToFile(base64: string, fileName: string, contentType: string) {
  const res: Response = await fetch(`data:${contentType};base64,${base64}`);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: contentType });
}

export const getFileNameFromResponse = (
  response: any,
  defaultName?: string,
  fileExtension: string = ".csv"
) => {
  let filename = response.headers["x-filename"];
  if (!filename) {
    try {
      const contentType = response.headers["content-disposition"];
      filename = contentType.split("filename=")[1].replace(fileExtension, "");
    } catch (e) {
      filename = defaultName;
    }
  }

  return filename;
};

export function formatFileSize(bytes: number, decimalPoint: number) {
  if (bytes == 0) return "0 Bytes";
  let k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getFileChunk(file: File, partNumber: number, partCount: number): Blob {
  const chunkSize = Math.ceil(file.size / partCount);

  const start = (partNumber - 1) * chunkSize;
  const end = Math.min(start + chunkSize, file.size);

  return file.slice(start, end);
}
