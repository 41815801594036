import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { NameCell } from "../../report-table-cells/name-cell";
import { HeaderCell } from "../../report-table-cells/header-cell";
import { formatNumber } from "helpers/formatNumber";
import i18next from "i18next";
import { TextCellWithTrend } from "../../report-table-cells/text-cell-with-trend";

export const getOrdersReportTableColumns = (t: i18next.TFunction) => {
  const columns: GridColDef[] = [
    {
      field: "full_name",
      headerName: "Name",
      renderHeader: () => <HeaderCell text={t("analytics:global:name")} />,
      flex: 0.5,
      sortable: true,
      renderCell: NameCell,
    },

    {
      field: "po_sent_count",
      headerName: "Orders sent",
      renderHeader: () => (
        <HeaderCell
          text={t("analytics:ordersReport:table:teamWorkload:columns:ordersSent:text")}
          tooltipTitle={t(
            "analytics:ordersReport:table:teamWorkload:columns:ordersSent:tooltipTitle"
          )}
        />
      ),
      flex: 0.5,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => (
        <TextCellWithTrend
          {...params}
          value={`${formatNumber(params.row.po_sent_count)}`}
          trendPercentage={params.row.po_sent_trend.trend_value}
          trendType={params.row.po_sent_trend.trend_type}
          trendColorForUp="primary"
          trendColorForDown="primary"
        />
      ),
      valueGetter: (params) => params.value,
    },
  ];

  return columns;
};
