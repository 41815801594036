import { Typography } from "@material-ui/core";
import { Box, Theme, alpha, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/styles";
import { GridCellParams } from "@mui/x-data-grid-pro-v5";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import { OrderMarkAsShippedModal } from "@next/modules/orders/modals/order-mark-as-shipped-modal";
import { ActionPanelType, LineItemType, ordersActions } from "@next/modules/orders/redux";
import {
  selectLineItemsTableSelectionModel,
  selectOrderActionPanelType,
} from "@next/modules/orders/redux/selectors";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useOrderDetailLineItemsTableData } from "./order-detail-line-items-table.hooks";
import { PO_PART_GRID_STATE } from "@next/constants/data-grid-state-constants";
import useLocalStorageGridState from "@next/hooks/useLocalStorageGridState-v5";
import { getUser } from "services/profile/profile.selectors";
import { CompanyTypeNext } from "@next/redux/types";
import { useQueryParam } from "@next/hooks/useQuery";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { RightPanelTabs } from "../order-detail-right-tabs/order-detail-right-tabs";

type Props = {
  isPortal?: boolean;
  headerHeight?: number;
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    table: {
      "& .highlightedCell": {
        backgroundColor: alpha(theme.palette.warning.main, 0.1),
      },

      "& .highlightedBlueCell": {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
    },
    tipRow: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginTop: "12px",
    },
    tipContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "16px",
      backgroundColor: theme.palette.divider,
      padding: "6px 12px 6px 6px",
      width: "fit-content",
      gap: "4px",
    },
  })
);

export const OrderDetailLineItemsTable: React.FC<Props> = ({ isPortal, headerHeight }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lineItemsTableSelectionModel = useSelector(selectLineItemsTableSelectionModel);
  const user = useSelector(getUser);
  const isBuyer = user?.type === CompanyTypeNext.DO;
  const { gridData } = useOrderDetailLineItemsTableData(isPortal);
  const theme = useTheme();
  const onlyForMobile = useMediaQuery(theme.breakpoints.down(937));
  const [_rightPanelTab, setRightPanelTab] = useQueryParam("panelTab");
  const orderActionPanelType = useSelector(selectOrderActionPanelType);

  const { apiRef } = useLocalStorageGridState(PO_PART_GRID_STATE);

  const dispatch = useDispatch();

  useEffect(() => {
    if (lineItemsTableSelectionModel?.length > 0) {
      setRightPanelTab(RightPanelTabs.ACTIONS_PANEL);
      dispatch(ordersActions.setOrderDetailRightTabOpen(true));
    }
  }, [lineItemsTableSelectionModel]);

  useEffect(() => {
    if (orderActionPanelType === ActionPanelType.ProposeQtyAndPriceChange) {
      dispatch(ordersActions.setLineItemsTableSelectionModel([]));
    }
  }, [orderActionPanelType]);

  const getCellClassName = (params: GridCellParams) => {
    const fieldsToCheck: string[] = [
      LineItemType.UPDATED_DELIVERY_DATE,
      LineItemType.QUANTITY,
      LineItemType.PRICE,
    ];

    if (fieldsToCheck.includes(params.field)) {
      const previousLineItem = params.row?.previous_line_item;
      const isFieldUpdated =
        isBuyer &&
        params.row?.updated_fields.includes(
          fieldsToCheck.find((field) => field === params.field) as LineItemType
        );
      if (previousLineItem && previousLineItem.hasOwnProperty(params.field)) {
        return "highlightedCell";
      }
      if (isFieldUpdated) {
        return "highlightedCell";
      }
    }

    if (
      orderActionPanelType === ActionPanelType.ModifyDeliveryDate &&
      params.field === LineItemType.UPDATED_DELIVERY_DATE
    ) {
      return "highlightedBlueCell";
    } else if (
      orderActionPanelType === ActionPanelType.ProposeQtyAndPriceChange &&
      (params.field === LineItemType.PRICE || params.field === LineItemType.QUANTITY)
    ) {
      return "highlightedBlueCell";
    }
    return "";
  };

  return (
    <Box>
      <Box className={classes.tipRow}>
        <Box className={classes.tipContainer}>
          <AdsClickIcon />
          <Typography variant="caption">
            <Trans i18nKey="orders:orderDetail:tipToModify" />
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {t("orders:orderDetail:selectedItems", {
            count: lineItemsTableSelectionModel?.length,
          })}
        </Typography>
      </Box>

      <Box
        mt="20px"
        height={onlyForMobile ? "600px" : (gridData?.rows.length ?? 0) * 40 + 100}
        className={classes.table}
      >
        <DataGridProV5
          apiRef={apiRef}
          rows={gridData?.rows || []}
          columns={gridData?.columns || []}
          rowHeight={40}
          disableSelectionOnClick
          hideFooter
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            dispatch(ordersActions.setLineItemsTableSelectionModel(newSelectionModel));
          }}
          selectionModel={lineItemsTableSelectionModel}
          getCellClassName={getCellClassName}
          isRowSelectable={(params) =>
            params.row.status !== "shipped" &&
            orderActionPanelType !== ActionPanelType.ProposeQtyAndPriceChange &&
            params.row !== undefined
          }
        />
      </Box>

      <OrderMarkAsShippedModal />
    </Box>
  );
};
