import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { TopicType } from "@next/modules/workspace/redux";
import { formatDateAndTime } from "helpers/formatDate";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  supplier: TopicType["rfq"]["winner"];
  seenAt: string;
};
export const TopicMessageSeenSupplierCard: FC<Props> = ({
  supplier: { name, picture },
  seenAt,
}) => {
  const { t } = useTranslation();
  const tooltipTitles = t("workspaceNext:QA:viewedBySomeoneFrom", {
    from: name,
  }).split("\n");
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} px={"24px"} py={"10px"}>
      <Tooltip
        title={
          <>
            {tooltipTitles.map((title, index) => (
              <Box key={index} textAlign={"center"}>
                {title}
              </Box>
            ))}
          </>
        }
      >
        <Avatar
          src={picture}
          alt={name}
          variant="square"
          sx={{ width: "24px", height: "24px", mr: "8px" }}
        />
      </Tooltip>
      <Typography variant="caption" color="textSecondary">
        {t("workspaceNext:QA:viewedAt", {
          date: formatDateAndTime(seenAt, "DD-MMM-YYYY"),
        })}
      </Typography>
    </Box>
  );
};
