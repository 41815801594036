import React from "react";
import { Field, Form, FormikContext, useFormik } from "formik";
import { GenericTextInput } from "components/utils/input/text";
import { Grid, TextField } from "@mui/material";
import { Typography } from "@material-ui/core";
import { FormikAttachFile } from "@next/components/formik-attach-file";
import {
  BundleFile,
  CreatePortalQuoteInput,
  UpdatePortalQuoteInput,
  workspaceNextActions,
} from "@next/modules/workspace/redux";
import { useDispatch, useSelector } from "react-redux";
import { Done } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@next/hooks/useQuery";
import { initialValues, simpleRFQFormValidationSchema } from "./simple-rfq-form.definitions";
import { currencyLabels, workTimeOptions } from "./simple-rfq-constants";
import {
  selectCreatePortalQuoteLoading,
  selectUpdatePortalQuoteLoading,
} from "@next/modules/workspace/redux/selectors";
import CustomButton from "@next/components/custom-button";
import AutocompleteOptions from "components/autocomplete-options/autocomplete-options.component";
import { CustomFileObject } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload.types";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import snackbarUtils from "@next/utils/snackbarUtils";

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
      marginRight: "12px",
      marginTop: "4px",
      fontSize: "12px",
    },
    fieldsStyle: {
      display: "flex",
      gap: "12px",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "12px",
    },
    textLabelColor: {
      color: theme.palette.text.secondary,
    },
    textLabelStyle: {
      minWidth: "135px",
      color: theme.palette.text.secondary,
    },
    boxStyles: {
      display: "flex",
      alignItems: "baseline",
      marginBottom: "15px",
    },
    reviseBtnStyle: {
      display: "flex",
      gap: "5px",
      padding: "8px 12px",
    },
  })
);

const SimpleRFQFormView = ({ portalData }) => {
  const { t } = useTranslation();
  const isCreatingQuote = useSelector(selectCreatePortalQuoteLoading);
  const isUpdatingQuote = useSelector(selectUpdatePortalQuoteLoading);
  const classes = useStyles();
  const [query] = useQuery();
  const rfqToken = query.rfq_token as string;
  const dispatch = useDispatch();

  const onAttachementDelete = (filesName: string) => {
    dispatch(
      workspaceNextActions.removeFileUploadProgress({
        filesName,
      })
    );
  };

  const handleFormSubmit = (values) => {
    const { delay, total_cost, delay_unit, currency, files, additional_details } = values;

    if (!delay && !total_cost && !files?.length && !additional_details) {
      snackbarUtils.error(t("workspaceNext:QA:simpleRFQ:emptyInputsError"));
      return;
    }

    const customFiles: CustomFileObject[] = files?.map((file: BundleFile) => ({
      file,
      pk: file?.pk || -1,
    }));

    const payload: any = {
      delay: delay ? parseFloat(delay) : null,
      delay_unit: delay_unit?.label ? delay_unit?.label : null,
      total_cost: total_cost ? parseFloat(total_cost) : null,
      currency: currency?.label ? currency?.label : null,
      additional_details: additional_details,
      files: customFiles,
      rfqToken,
      isSimpleRFQ: true,
    };

    if (portalData?.quotation?.pk) {
      dispatch(
        workspaceNextActions.updatePortalQuoteRequest({
          ...payload,
          quotation_id: portalData?.quotation?.pk,
        } as UpdatePortalQuoteInput)
      );
    } else {
      dispatch(workspaceNextActions.createPortalQuoteRequest(payload as CreatePortalQuoteInput));
    }
  };

  const form = useFormik({
    initialValues: initialValues(portalData),
    validationSchema: simpleRFQFormValidationSchema,
    onSubmit: handleFormSubmit,
  });

  const { values, errors, submitCount, touched, setFieldValue } = form;
  const hasAtLeastOneInputError =
    submitCount > 0 &&
    !values.delay &&
    !values.total_cost &&
    !values.files?.length &&
    !values.additional_details;
  const renderAtLeastOneInputError = hasAtLeastOneInputError ? (
    <p className={classes.error}>{t("common:validation:atLeastOneInputIsRequired")}</p>
  ) : null;

  return (
    <div>
      <FormikContext.Provider value={form}>
        <Form>
          {/* Total cost field */}
          <Grid className={classes.fieldsStyle} xs={12}>
            <Grid item xs={1.6}>
              <Typography variant="body2" className={classes.textLabelColor}>
                {t("workspaceNext:QA:simpleRFQ:totalCost")}
              </Typography>
            </Grid>
            <Grid item xs={5.2}>
              <Field
                sx={
                  touched.total_cost &&
                  errors.total_cost && {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "red",
                    },
                  }
                }
                name="total_cost"
                component={TextField}
                fullWidth
                type="number"
                placeholder="0"
                value={values.total_cost}
                onChange={(e: any) => {
                  setFieldValue("total_cost", e.target.value);
                }}
              />
              {renderAtLeastOneInputError}
            </Grid>
            <Grid item xs={5.2} style={{ alignSelf: "flex-start" }}>
              <Field
                sx={
                  touched.currency &&
                  errors.currency && {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "red",
                    },
                  }
                }
                name="currency"
                component={AutocompleteOptions}
                label="Currency"
                value={values.currency}
                staticsData={currencyLabels}
                disabled={false}
                disableClearable
              />
            </Grid>
          </Grid>
          {/* Lead time field */}
          <Grid xs={12} className={classes.fieldsStyle}>
            <Grid item xs={1.6}>
              <Typography variant="body2" className={classes.textLabelColor}>
                {t("workspaceNext:QA:simpleRFQ:leadTime")}
              </Typography>
            </Grid>
            <Grid item xs={5.2}>
              <Field
                sx={
                  touched.delay &&
                  errors.delay && {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "red",
                    },
                  }
                }
                name="delay"
                component={TextField}
                fullWidth
                type="number"
                placeholder="0"
                value={values.delay}
                onChange={(e: any) => {
                  setFieldValue("delay", e.target.value);
                }}
              />
              {renderAtLeastOneInputError}
            </Grid>
            <Grid item xs={5.2} style={{ alignSelf: "flex-start" }}>
              {/* LeadTimeAutoComplete */}
              <Field
                sx={
                  touched.delay_unit &&
                  errors.delay_unit && {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "red",
                    },
                  }
                }
                name="delay_unit"
                component={AutocompleteOptions}
                label="Lead Time"
                value={values.delay_unit}
                staticsData={workTimeOptions}
                disabled={false}
                disableClearable
              />
            </Grid>
          </Grid>
          <Grid
            display="flex"
            gap="12px"
            flexDirection="row"
            xs={12}
            alignItems="center"
            marginBottom="12px"
          >
            <Grid item xs={1.6}>
              <Typography variant="body2" className={classes.textLabelColor}>
                Note
              </Typography>
            </Grid>
            <Grid item xs={10.4}>
              <GenericTextInput
                name="additional_details"
                label={t("workspaceNext:QA:simpleRFQ:addNote")}
                multiline
                rows={"2"}
                defaultValue={portalData?.quotation?.additional_details || null}
                hideHelperText
              />
              {renderAtLeastOneInputError}
            </Grid>
          </Grid>
          {/*file attachment*/}
          <Grid className={classes.fieldsStyle} xs={12}>
            <Grid item xs={1.6}>
              <Typography variant="body2" className={classes.textLabelColor}>
                {t("workspaceNext:QA:simpleRFQ:files")}
              </Typography>
            </Grid>
            <Grid item xs={10.4}>
              <Field
                name="files"
                disableAcceptSpecificTypes
                component={FormikAttachFile}
                onChangeFiles={(files: (File | FileData)[]) => setFieldValue("files", files)}
                onDeleteFiles={onAttachementDelete}
              />
              {renderAtLeastOneInputError}
            </Grid>
          </Grid>
          <Grid xs={12}>
            <CustomButton
              fullWidth
              type="submit"
              disabled={isCreatingQuote || isUpdatingQuote}
              variant="contained"
              loading={isCreatingQuote || isUpdatingQuote}
            >
              <Done /> {t("workspaceNext:QA:simpleRFQ:submit")}
            </CustomButton>
          </Grid>
        </Form>
      </FormikContext.Provider>
    </div>
  );
};

export default SimpleRFQFormView;
