import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { CustomTypography } from "@next/components/custom-typography";
import * as S from "../analytic-card/analytic-card.styled";
import React from "react";
import { TrendColor } from "../analytic-card/analytic-card";
import { AnalyticTrend } from "../../redux";

export type TrendValueWithTrendChip = {
  trendPercentage?: number;
  trendType?: AnalyticTrend["trend"];
  trendColorForUp?: TrendColor;
  trendColorForDown?: TrendColor;
};

export const TextCellWithTrend: React.FC<GridRenderCellParams<any> & TrendValueWithTrendChip> = (
  params
) => {
  return (
    <Box display="flex" alignItems="center" gap={"8px"}>
      {params.trendPercentage > 0 ? (
        <S.TrendChip
          isTrendUp={params.trendType === "up"}
          trendUpColor={params.trendColorForUp}
          trendDownColor={params.trendColorForDown}
          label={`${params.trendType === "up" ? "+" : "-"}${params.trendPercentage}%`}
        />
      ) : (
        <S.TrendChip hasNoTrend={true} label="-%" />
      )}
      <CustomTypography variant="body2">{params.value}</CustomTypography>
    </Box>
  );
};
