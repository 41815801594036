import { Box, Button, styled } from "@mui/material";

export const StyledBorderedBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "72px",
  padding: "16px",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: "primary" | "secondary" }>(({ theme, color }) => ({
  width: "100%",
  height: "40px",
  backgroundColor:
    color === "primary" ? theme.palette.primary.main : theme.palette.background.paper,
  color: color === "primary" ? theme.palette.common.white : theme.palette.text.primary,
  "&:hover": {
    backgroundColor:
      color === "primary" ? theme.palette.primary.dark : theme.palette.background.paper,
  },
  "&:disabled": {
    opacity: 0.5,
    backgroundColor:
      color === "primary" ? theme.palette.primary.main : theme.palette.background.paper,
    color: color === "primary" ? theme.palette.common.white : theme.palette.text.primary,
  },
  border: color === "primary" ? "none" : `1px solid ${theme.palette.divider}`,
}));

export const StyledNotesInput = styled("textarea")(({ theme }) => ({
  margin: "24px",
  borderRadius: "8px",
  resize: "none",
  minHeight: "calc(100% - 190px)",
  height: "auto",
  padding: "8px 12px",
  borderColor: theme.palette["inputs"]["darkBorder"],
}));

export const StyledCenteredBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "calc(100% - 190px)",
}));

export const StyledContentBox = styled(Box)(() => ({
  padding: "24px",
}));
