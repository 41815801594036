import React, { useEffect } from "react";
import * as S from "./side-drawer-message-card.styled";
import { Checkbox } from "@mui/material";
import { useDispatch } from "react-redux";
import { vendorManagementActions } from "@next/modules/vendor-management/redux";
import { formatDateAndTime } from "helpers/formatDate";
import moment from "moment";

type Props = {
  id: number;
  body: string;
  author: string;
  createdAt: string;
  resolvedAt: string;
  allowToMarkAsResolved: boolean;
};

export const SideDrawerMessageCard: React.FC<Props> = ({
  id,
  body,
  author,
  createdAt,
  resolvedAt = null,
  allowToMarkAsResolved,
}) => {
  const dispatch = useDispatch();
  const [resolvedAtLocalState, setResolvedAtLocalState] = React.useState<string | null>(resolvedAt);
  const handleMarkAsResolved = (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    dispatch(
      vendorManagementActions.updateMessageWithinThreadRequest({
        messageIdWithinThread: id,
        resolved_at: checked ? moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSS") : null,
      })
    );
    setResolvedAtLocalState(
      checked ? formatDateAndTime(Date.now(), "MMM D, YYYY", "h:mm a") : null
    );
  };
  useEffect(() => {
    setResolvedAtLocalState(resolvedAt);
  }, [resolvedAt]);

  return (
    <S.StyledCardBox>
      <S.StyledCardHeaderBox>
        <S.StyledAuthorTypography variant="body2" tooltipTitle={null}>
          {author}
        </S.StyledAuthorTypography>

        <S.StyledDateTypography variant="body2" tooltipTitle={null}>
          {createdAt}
        </S.StyledDateTypography>
      </S.StyledCardHeaderBox>
      <S.StyledBodyTypography
        variant="body2"
        tooltipTitle={null}
        resolved={!!resolvedAtLocalState && allowToMarkAsResolved}
      >
        {body}
      </S.StyledBodyTypography>
      {allowToMarkAsResolved ? (
        <S.StyledCardFooterBox>
          <S.StyledDateTypography
            variant="body2"
            tooltipTitle={null}
            resolved={!!resolvedAtLocalState}
          >
            {!!resolvedAtLocalState ? resolvedAtLocalState : "Mark comment as resolved"}
          </S.StyledDateTypography>
          <S.StyledCheckboxContainer tooltipTitle={null}>
            <Checkbox checked={!!resolvedAtLocalState} onChange={handleMarkAsResolved} />
          </S.StyledCheckboxContainer>
        </S.StyledCardFooterBox>
      ) : null}
    </S.StyledCardBox>
  );
};
