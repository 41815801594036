import { Box, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { TabPanel } from "@next/components/tab-panel";
import { TabsView } from "@next/components/tabs-view";
import { ActionPanelType, OrderState, ordersActions } from "@next/modules/orders/redux";
import {
  selectOrderByIsPortal,
  selectRevisionProposals,
  selectRevisionProposalsLoading,
} from "@next/modules/orders/redux/selectors";
import { Topic } from "@next/modules/workspace/components/questions-answers/topic/topic";
import {
  useInternalChatTopic,
  usePublicQuestionAnswerData,
} from "@next/modules/workspace/pages/public-question-answer/public-question-answer.hooks";
import {
  selectQALatestPOMessageSeenBySupplier,
  selectQATopicLoading,
} from "@next/modules/workspace/redux/selectors";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { OrderDetailFollowupsTimeline } from "../order-detail-followups-timeline/order-detail-followups-timeline";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { AppThemeV5 } from "layout/AppTheme";
import { useTranslation } from "react-i18next";
import { OrderDetailActionsPanel } from "../order-detail-actions-panel/order-detail-actions-panel";
import { useQueryParam } from "@next/hooks/useQuery";
import { InternalCommunication } from "@next/modules/workspace/components/internal-communication/internal-communication";
import { createStyles, makeStyles } from "@mui/styles";

type Props = {
  isPortal?: boolean;
  setRightTabOpen?: (isOpen: boolean) => void;
  rightTabOpen?: boolean;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    communciationContainer: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
    },
    unreadIcon: {
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      background: theme.palette.primary.main,
    },
  })
);

export enum RightPanelTabs {
  SUPPLIER_CHAT = 0,
  INTERNAL_CHAT = 1,
  ACTIONS_PANEL = 2,
  AUTOMATED_FOLLOWUPS = 3,
}

export const OrderDetailRightTabs: React.FC<Props> = ({
  isPortal,
  setRightTabOpen,
  rightTabOpen,
}) => {
  const { t } = useTranslation();
  const params = useParams<{ qaToken?: string }>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const orderDetail = useSelector(selectOrderByIsPortal(isPortal));
  const fetchRevisionProposalsLoading = useSelector(selectRevisionProposalsLoading);
  const revisionProposal = useSelector(selectRevisionProposals);
  const isDraftOrder = !isPortal && orderDetail?.state?.slug === OrderState.UNPUBLISHED;
  const theme = useTheme();
  const onlyForMobile = useMediaQuery(theme.breakpoints.down(937));

  const { question } = usePublicQuestionAnswerData(
    isPortal ? params?.qaToken : orderDetail?.qa_token,
    isDraftOrder
  );

  const latestSeenStatusContent = useSelector(selectQALatestPOMessageSeenBySupplier);

  const { internalChatTopic, internalChatTopicLoading } = useInternalChatTopic(
    orderDetail.rfq.pk,
    isPortal
  );

  const questionLoading = useSelector(selectQATopicLoading);

  const [rightPanelTab, setRightPanelTab] = useQueryParam(
    "panelTab",
    (isPortal && orderDetail?.step === "sent" && !orderDetail?.is_cancelled) ||
      (!isPortal && revisionProposal?.length > 0)
      ? RightPanelTabs.ACTIONS_PANEL
      : RightPanelTabs.SUPPLIER_CHAT
  );

  useEffect(() => {
    dispatch(ordersActions.setOrderActionPanelType(ActionPanelType.Default));
  }, []);

  const handleOpenClose = () => setRightTabOpen(!rightTabOpen);
  const rotateChevron = rightTabOpen ? "rotate(0)" : "rotate(180deg)";

  const tabItems = useMemo(
    () => [
      {
        tabName: (
          <Box className={classes.communciationContainer}>
            {t("orders:orderDetail:rightTabs:supplierChat")}
            {question?.unseen_messages_count > 0 && <Box className={classes.unreadIcon}></Box>}
          </Box>
        ),
        value: RightPanelTabs.SUPPLIER_CHAT,
      },
      {
        tabName: (
          <Box className={classes.communciationContainer}>
            {t("orders:orderDetail:rightTabs:internalChat")}
            {internalChatTopic?.unseen_messages_count > 0 && (
              <Box className={classes.unreadIcon}></Box>
            )}
          </Box>
        ),
        hide: isPortal,
        value: RightPanelTabs.INTERNAL_CHAT,
      },
      {
        tabName: t("orders:orderDetail:rightTabs:actionsPanel"),
        value: RightPanelTabs.ACTIONS_PANEL,
      },
      {
        tabName: t("orders:automatedFollowups"),
        hide: isPortal,
        value: RightPanelTabs.AUTOMATED_FOLLOWUPS,
      },
    ],
    [isPortal, t]
  );

  const tabHandleChange = (_: React.ChangeEvent<{}>, value: any) => {
    setRightPanelTab(value);
  };

  return (
    <Box
      mt={onlyForMobile ? "10px" : "0px"}
      mb={onlyForMobile ? "12px" : "0px"}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box display="flex">
        {!onlyForMobile ? (
          <Box
            borderBottom={`1px solid ${AppThemeV5.palette.divider}`}
            mt="4px"
            pl={rightTabOpen ? "4px" : "0px"}
          >
            <Tooltip
              title={
                rightTabOpen
                  ? t("orders:orderDetail:rightTabs:collapseSidePanel")
                  : t("orders:orderDetail:rightTabs:openSidePanel")
              }
            >
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleOpenClose}
                style={{
                  transform: rotateChevron,
                  transition: "all 0.2s linear",
                }}
              >
                <KeyboardTabIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null}

        <Box flex={1} display={rightTabOpen ? "unset" : "none"} overflow="auto">
          <TabsView
            value={rightPanelTab as string}
            tabItems={tabItems}
            handleChange={tabHandleChange}
            dividerMarginLeft={rightTabOpen ? "-30px" : "0px"}
            dividerMarginRight={"0"}
          />
        </Box>
      </Box>

      {!rightTabOpen && !onlyForMobile ? (
        <Typography
          sx={{
            textOrientation: "sideways",
            writingMode: "vertical-lr",
            transform: "rotate(180deg)",
            marginTop: "16px",
            marginRight: "12px",
            cursor: "pointer",
          }}
          onClick={handleOpenClose}
          color="secondary"
        >
          {t("common:open")}
        </Typography>
      ) : null}

      <TabPanel
        destroyOnHide
        value={rightPanelTab as string}
        index={RightPanelTabs.SUPPLIER_CHAT}
        sx={{
          height: "80%",
          flex: 1,
        }}
        innerProps={{
          sx: { height: "100%" },
        }}
      >
        <Box display={rightTabOpen ? "flex" : "none"} sx={{ height: "100%" }}>
          {!questionLoading && question ? (
            <Topic
              topic={question}
              latestSeenStatusContent={latestSeenStatusContent}
              initiallyOpen
              publicToken={orderDetail?.qa_token}
              showToastOnMessage={false}
              showExpandIcon={false}
              TitleComponent={null}
              disableAccordion={true}
              isPortal={isPortal}
            />
          ) : null}
        </Box>
      </TabPanel>

      <TabPanel
        destroyOnHide
        value={rightPanelTab as string}
        index={RightPanelTabs.INTERNAL_CHAT}
        sx={{
          height: "80%",
          flex: 1,
        }}
        innerProps={{
          sx: { height: "100%" },
        }}
      >
        <Box display={rightTabOpen ? "flex" : "none"} sx={{ height: "100%" }}>
          {!internalChatTopicLoading && internalChatTopic ? (
            <InternalCommunication topic={internalChatTopic} isPortal={isPortal} isInOrderDetail />
          ) : null}
        </Box>
      </TabPanel>

      <TabPanel
        value={rightPanelTab as string}
        index={RightPanelTabs.ACTIONS_PANEL}
        destroyOnHide
        sx={{
          height: "80%",
          flex: 1,
        }}
        innerProps={{
          sx: {
            height: "100%",
          },
        }}
      >
        <Box
          display={rightTabOpen ? "flex" : "none"}
          sx={{
            height: "100%",
            position: "relative",
          }}
        >
          <OrderDetailActionsPanel loading={fetchRevisionProposalsLoading} isPortal={isPortal} />
        </Box>
      </TabPanel>

      <TabPanel
        value={rightPanelTab as string}
        index={RightPanelTabs.AUTOMATED_FOLLOWUPS}
        destroyOnHide
      >
        <Box position="sticky" top="0px" height="85vh" display={rightTabOpen ? "unset" : "none"}>
          <Box mt={"24px"}>
            <OrderDetailFollowupsTimeline />
          </Box>
        </Box>
      </TabPanel>
    </Box>
  );
};
