import React, { useEffect, useState } from "react";
import { FormControl, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormTitle } from "../../form-title/form-title";
import { FormFieldBox } from "../../form-field-box";
import { FormFieldLabel, InfoTitle } from "../../form-field-label/form-field-label";
import { BufferStockStrategyFormTable } from "../../table/buffer-stock-strategy-form-table/buffer-stock-strategy-form-table";
import { TableLinesBox } from "../../table-lines-box/table-lines-box";
import { SideDrawer } from "../../side-drawer/side-drawer";
import { FormBoxWrapper } from "../../form-box/form-box-wrapper";
import {
  BufferStockStrategyFormData,
  PaginationSizeForFormTable,
  PartialStep,
  QuestionnaireForms,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectCurrentMessageThread,
  selectFormData,
  selectIsAllStepsCompletedExceptBeforeCurrentStepCompletion,
  selectIsFetchSpecByClickedThread,
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepApproved,
  selectIsFormStepCompleted,
  selectIsFormStepInReview,
  selectIsInternalNotesDrawerOpen,
  selectIsLastStep,
  selectIsSideDrawerOpen,
  selectIsThereAnyStepInReviewProcess,
  selectIsThereUnsavedChangesInFormStep,
  selectSpecsOfBufferStockStrategy,
  selectTriggerForFetchingSpecsOfStep,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { showSavedMessage } from "@next/modules/vendor-management/utils/showSavedMessage";
import { showThreadsNeedToBeResolvedMessage } from "@next/modules/vendor-management/utils/showThreadsNeedToBeResolvedMessage";
import * as S from "./buffer-stock-strategy-form.styled";

export const BufferStockStrategyForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isThereUnsavedChanges = useSelector(selectIsThereUnsavedChangesInFormStep);
  const isFormCompleted = useSelector(selectIsFormStepCompleted);
  const isFormInReview = useSelector(selectIsFormStepInReview);
  const isFormApproved = useSelector(selectIsFormStepApproved);
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const isAllStepsCompletedExceptBeforeCurrentStepCompletion = useSelector(
    selectIsAllStepsCompletedExceptBeforeCurrentStepCompletion
  );
  const isLastStep = useSelector(selectIsLastStep);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeFormStep = steps[activeFormStepIndex];
  const tableData = useSelector(selectSpecsOfBufferStockStrategy);
  const [page, setPage] = useState(1);
  const formData = useSelector(selectFormData) as BufferStockStrategyFormData;
  const [hideCompletedTableLines, setHideCompletedTableLines] = useState(false);
  const isDrawerOpen = useSelector(selectIsSideDrawerOpen);
  const isInternalNotesDrawerOpen = useSelector(selectIsInternalNotesDrawerOpen);

  const triggerStateForFetchingSpecs = useSelector(selectTriggerForFetchingSpecsOfStep);
  const isThereAnyStepInReview = useSelector(selectIsThereAnyStepInReviewProcess);
  const currentMessageThread = useSelector(selectCurrentMessageThread);
  const isFetchSpecByClickedThread = useSelector(selectIsFetchSpecByClickedThread);
  const isFormFrozen = isFormApproved || isFormDisplayForValidationManagement;
  const { handleSubmit, control, getValues } = useForm<BufferStockStrategyFormData>({
    defaultValues: formData,
  });

  useEffect(() => {
    if (formData) {
      fetchSpecsOfStep();

      dispatch(
        vendorManagementActions.fetchNumberOfUnapprovedLinesOfStepRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
        })
      );
    }
  }, [page, hideCompletedTableLines, triggerStateForFetchingSpecs, isFetchSpecByClickedThread]);

  const fetchSpecsOfStep = () => {
    if (isFetchSpecByClickedThread) {
      dispatch(
        vendorManagementActions.fetchSpecsOfBufferStockStrategyRequest({
          companyId: companyId,
          pagination: { page: 1, pageSize: PaginationSizeForFormTable },
          filter_completed: false,
          entityId: currentMessageThread?.entity_id,
        })
      );
    } else {
      dispatch(
        vendorManagementActions.fetchSpecsOfBufferStockStrategyRequest({
          companyId: companyId,
          pagination: { page: page, pageSize: PaginationSizeForFormTable },
          filter_completed: hideCompletedTableLines,
        })
      );
    }
  };

  const saveChanges = (isForCompletion: boolean) => {
    const additional_comment = getValues("additional_comment");
    dispatch(
      vendorManagementActions.saveAdditionalCommentFieldOfFormRequest({
        companyId,
        additionalComment: additional_comment,
        stepName: QuestionnaireForms.BufferStockStrategy,
      })
    );

    const fieldOfStep = isForCompletion ? "is_completed" : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true, activeFormStepIndex);
    if (!isForCompletion) {
      showSavedMessage();
    }
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<BufferStockStrategyFormData> = (data) => {
    if (activeFormStep?.unresolved_threads_count !== 0) {
      showThreadsNeedToBeResolvedMessage();
      return;
    }

    const isAllLinesCompleted = tableData?.results?.uncompleted_count === 0;
    if (isAllLinesCompleted) {
      makeFormStepCompletionProcess();
    } else {
      showInvalidMessage();
    }
  };

  const makeFormStepCompletionProcess = () => {
    saveChanges(true);
    showCompletedMessage();
    if (isAllStepsCompletedExceptBeforeCurrentStepCompletion) {
      showThankYouModal();
    } else if (!isLastStep && !isThereAnyStepInReview) {
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    setActiveFormStepIndex(newActiveStepIndex);
  };

  const setActiveFormStepIndex = (index: number) => {
    updateFieldStatusOfStepForSaveAndCompletion("is_active", true, index);
  };

  const showThankYouModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.THANK_YOU_MODAL,
      })
    );
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled" | "is_active",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
        onSuccess:
          key === "is_completed" && value
            ? () => updateApprovalOfStepAfterCompletionChange("changes_done")
            : key === "is_completed" && !value
              ? () => updateApprovalOfStepAfterCompletionChange("changes_requested")
              : undefined,
      })
    );
  };

  const updateApprovalOfStepAfterCompletionChange = (
    statusParam: "changes_done" | "changes_requested"
  ) => {
    if (
      isFormInReview &&
      activeFormStep?.unresolved_threads_count === 0 &&
      statusParam === "changes_done"
    ) {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
          status: statusParam,
        })
      );
    } else if (
      activeFormStep?.approval_status === "changes_done" &&
      statusParam === "changes_requested"
    ) {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
          status: statusParam,
        })
      );
    }
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false, activeFormStepIndex);
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(true));
    } else if (!isThereUnsavedChanges) {
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(true));
    }
  };

  return (
    <>
      <FormParentBox>
        <FormBoxWrapper isDrawerOpen={isDrawerOpen || isInternalNotesDrawerOpen}>
          <FormBoxLg
            isCompleted={isFormCompleted}
            isApproved={isFormApproved}
            isInReview={isFormInReview && activeFormStep?.unresolved_threads_count !== 0}
          >
            <FormTitle
              title={"Buffer stock strategy"}
              isCompleted={isFormCompleted}
              isApproved={isFormApproved}
              isInReview={isFormInReview && activeFormStep?.unresolved_threads_count !== 0}
            />

            <FormFieldBox>
              <FormFieldLabel label="Buffer stock Strategy : Stock Management from B to A per spec" />

              <FormFieldLabel label="For stocks Columns : We ask for the current level of stock of B0xxx and A0xxx to estimate the real PoE. For non Standard A0 specs : such as A2PS, A2MS, etc.. please enter 0." />

              {!isFormDisplayForValidationManagement ? (
                <>
                  <TableLinesBox
                    completedCount={tableData?.results?.completed_count}
                    totalCount={tableData?.results?.total_count}
                    hideCompletedTableLines={hideCompletedTableLines}
                    setHideCompletedTableLines={setHideCompletedTableLines}
                    setPage={setPage}
                  />

                  <InfoTitle />
                </>
              ) : null}

              <S.StyledTableWrapper>
                <BufferStockStrategyFormTable
                  hideCompletedTableLines={hideCompletedTableLines}
                  page={page}
                  pageSize={PaginationSizeForFormTable}
                  setPage={setPage}
                  isTableDisabled={isFormFrozen}
                />
              </S.StyledTableWrapper>
            </FormFieldBox>

            <FormControl margin="none">
              <Controller
                name="additional_comment"
                control={control}
                defaultValue={formData?.additional_comment || ""}
                render={({ field: { onChange, value } }) => (
                  <S.StyledFormFieldBox>
                    <TextField
                      sx={{ width: "100%" }}
                      multiline
                      rows={3}
                      placeholder="Additional comment..."
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e);
                        checkInputChange();
                      }}
                      InputProps={{
                        readOnly: isFormFrozen,
                      }}
                    />
                  </S.StyledFormFieldBox>
                )}
              />
            </FormControl>
          </FormBoxLg>
        </FormBoxWrapper>

        <SideDrawer isThreadful={true} />
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
        isApproved={isFormApproved}
        isInReview={isFormInReview && activeFormStep?.unresolved_threads_count !== 0}
        isShowReviewButton={false}
      />
    </>
  );
};
