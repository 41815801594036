import { createErrorMessage } from "helpers/createErrorMessage";
import { frontendUrl } from "urls";
import snackbarUtils from "./snackbarUtils";

const publicPaths = [
  frontendUrl.answerQuestionView,
  frontendUrl.publicRfqOLD,
  frontendUrl.publicRfqAmq,
  frontendUrl.publicRfqGeneral,
  frontendUrl.publicItbItnb,
  frontendUrl.login2FA,
  frontendUrl.portal,
  frontendUrl.publicPreferences,
  frontendUrl.airbusProcurement,
];

export const checkIsPublicPage = () => {
  // TODO: Find best way to fix this
  const pathname = window.location?.pathname;
  return publicPaths.some((path) => pathname.includes(path));
};

export const convertObjectToFormData = (obj: any, attachmentsKey: string = "attachments") => {
  const formData = new FormData();

  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (!obj[key]) return;

      if (key === attachmentsKey) {
        if (obj[attachmentsKey]?.length) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          obj[attachmentsKey]?.forEach((item: any) => {
            formData.append(attachmentsKey, item);
          });
        } else {
          return;
        }
      } else {
        formData.append(key, obj[key]);
      }
    });
  }

  return formData;
};

export const genericErrorHandler = (error: any) => {
  // Handling 401 errors in axios api
  if (error && error?.response?.status !== 401) {
    const errorMessage = createErrorMessage(error);

    if (errorMessage) {
      snackbarUtils.error(errorMessage);
    }
  }
};

export const handleAPIMockDataExecution = <T>(
  data: T,
  time: number = 1000
): Promise<{ data: T }> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ data });
    }, time);
  });
};
