import React from "react";
import { Grid } from "@mui/material";
import { RadialBarChart } from "../charts/radial-bar-chart/radial-bar-chart";
import { useSelector } from "react-redux";
import { selectReadinessDashboardData } from "@next/modules/vendor-management/redux/selectors";
import * as S from "./readiness-dashboard-cockpit.styled";

export const ReadinessDashboardCockpit: React.FC = () => {
  const dashboardData = useSelector(selectReadinessDashboardData);
  const summaries = dashboardData?.summary;

  return (
    <S.StyledBox>
      <Grid container sx={{ width: "97%", justifyContent: "center" }}>
        {summaries?.map((data, index) => (
          <S.StyledGrid item xs={12} md={6} lg={2.4} key={index}>
            <RadialBarChart
              slug={data?.slug}
              current_value={data?.current_value}
              criteria_value={data?.criteria_value}
            />
          </S.StyledGrid>
        ))}
      </Grid>
    </S.StyledBox>
  );
};
