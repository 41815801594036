import React from "react";
import { AppTheme } from "layout/AppTheme";
import Chart from "react-apexcharts";
import {
  ChartLabelType,
  ChartLabels,
} from "../../analytic-graph-card/analytic-graph-card-legend-list/analytic-graph-card-chart-labels";
import { formatNumber } from "helpers/formatNumber";
import { OrdersReportChart } from "@next/modules/analytics/redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAnalyticFilters } from "@next/modules/analytics/redux/selectors";

const colors = [AppTheme.palette.success.light, AppTheme.palette.primary.main];

export const OrdersReportExpectedReceptionValueChart: React.FC<OrdersReportChart> = ({
  id,
  x_axis,
  y_axes,
}) => {
  const { t } = useTranslation();
  const analyticFilters = useSelector(selectAnalyticFilters);

  const chartLabels = [
    {
      name: t("analytics:ordersReport:charts:expectedReceptionValue:valueReceived", {
        currency: analyticFilters.currency,
      }),
      type: ChartLabelType.COLUMN,
    },
    {
      name: t("analytics:ordersReport:charts:expectedReceptionValue:valueExpected", {
        currency: analyticFilters.currency,
      }),
      type: ChartLabelType.COLUMN,
    },
  ];

  const data = {
    series: [
      {
        name: chartLabels[0].name,
        data: y_axes[0].values,
      },
      {
        name: chartLabels[1].name,
        data: y_axes[1].values,
      },
    ],
    options: {
      chart: {
        type: "bar" as "bar",
        stacked: true,
        toolbar: {
          autoSelected: "zoom" as "zoom",
          tools: {
            pan: false,
            selection: false,
            zoom: false,
          },
        },
        zoom: {
          enabled: true,
          type: "x" as "x",
          autoScaleYaxis: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "28%",
          borderRadius: 5,
          borderRadiusWhenStacked: "all" as "all",
          borderRadiusApplication: "around" as "around",
        },
      },
      stroke: {
        width: 0.5,
        colors: ["#fff"],
      },
      dataLabels: {
        show: false,
        enabled: false,
      },
      xaxis: {
        categories: x_axis,
        tickPlacement: "on",
        axisBorder: {
          show: false,
        },
      },
      yaxis: [
        {
          labels: {
            formatter: (value: number) => formatNumber(value),
          },
          title: {
            text: t("analytics:ordersReport:charts:shipment:shippedItems"),
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      legend: {
        show: false,
      },
      colors: [colors[0], colors[1]],
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <>
      <ChartLabels chartLabels={chartLabels} colors={colors} />
      <Chart options={data.options} series={data.series} type="bar" height={"370"} />
    </>
  );
};
