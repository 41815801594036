import React from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import * as S from "./analytic-graph-card.styled";
import { Divider } from "@material-ui/core";

export const AnalyticGraphCardLoading: React.FC = () => {
  const bars = Array.from({ length: 20 }); // Adjust for the number of bars you want

  return (
    <S.RootBox>
      <div style={{ width: "100%" }}>
        <S.HeaderDiv>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h3">
              <Skeleton width="20%" />
            </Typography>
            <S.InnerDiv sx={{ gap: "50px" }}>
              <Skeleton width={250} height={120} />
              <Divider
                orientation="vertical"
                flexItem
                style={{ alignSelf: "center", height: "5rem" }}
              />
              <Skeleton width={250} height={120} />
            </S.InnerDiv>
            <S.InnerDiv sx={{ gap: "20px" }}>
              <Skeleton width={100} height={40} />
              <Divider
                orientation="vertical"
                flexItem
                style={{ alignSelf: "center", height: "2rem" }}
              />
              <Skeleton width={100} height={40} />
              <Divider
                orientation="vertical"
                flexItem
                style={{ alignSelf: "center", height: "2rem" }}
              />
              <Skeleton width={100} height={40} />
            </S.InnerDiv>
          </Box>
          <Box sx={{ width: "100%" }}>
            {/* Y-axis and bars */}
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mt: 3 }}>
              {/* Y-axis labels */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",

                  gap: 2,
                }}
              >
                {Array.from({ length: 12 }).map((_, index) => (
                  <Skeleton key={index} width={30} height={15} />
                ))}
              </Box>

              {/* Bars */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",

                  gap: 4.5,
                }}
              >
                {bars.map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    sx={{
                      width: 50,
                      height: `${Math.random() * 250 + 50}px`, // Random heights for skeleton effect
                    }}
                  />
                ))}
              </Box>
            </Box>

            {/* X-axis labels */}
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              {bars.map((_, index) => (
                <Skeleton key={index} width={30} height={15} />
              ))}
            </Box>
          </Box>
        </S.HeaderDiv>
      </div>
    </S.RootBox>
  );
};
