import { GenericUser } from "@next/modules/profile/redux";
import { RFQSupplierType } from "@next/modules/workspace/components/rfq-creation-form/rfq-creation.form.types";
import { CURRENCY_OPTIONS } from "@next/utils/countryList";

export type AnalyticsState = {
  requestsReportLoading: boolean;
  requestsReportError?: Error | undefined;
  requestsReport: RequestsReport | undefined;

  ordersReportLoading: boolean;
  ordersReportError?: Error | undefined;
  ordersReport: OrdersReport | undefined;

  ordersVolumeChartLoading: boolean;
  ordersVolumeChartError?: Error | undefined;
  ordersVolumeChart: OrdersReportChart | undefined;

  ordersExpectedReceiptValueChartLoading: boolean;
  ordersExpectedReceiptValueChartError?: Error | undefined;
  ordersExpectedReceiptValueChart: OrdersReportChart | undefined;

  ordersOnTimeDeliveryChartLoading: boolean;
  ordersOnTimeDeliveryChartError?: Error | undefined;
  ordersOnTimeDeliveryChart: OrdersReportChart | undefined;

  ordersItemLatenessChartLoading: boolean;
  ordersItemLatenessChartError?: Error | undefined;
  ordersItemLatenessChart: OrdersReportChart | undefined;

  supplierReportLoading: boolean;
  supplierReportError?: Error | undefined;
  supplierReport: SupplierReport | undefined;

  exportPOAnalyticsAsExcelLoading: boolean;
  exportPOAnalyticsAsExcelError?: Error | undefined;

  analyticFilters: AnalyticFilters;
};

export type AnalyticFilters = {
  selectedSuppliers: RFQSupplierType[];
  timeframe: {
    key: string;
    start: string;
    end: string;
  };
  selectedBuyers?: GenericUser[];
  currency?: CURRENCY_OPTIONS;
};

export type RequestsReport = {
  targeted_supplier_count: number;
  rfqs_count: number;
  parts_count: number;
  total_value: number;
  total_value_private: number;
  total_value_public: number;
  single_sourcing_count: number;
  single_sourcing_percentage: number;
  average_quotes_per_rfq: number;
  response_rate: number;
  quoted_quotation_parts_count: number;
  quoted_quotation_parts_percentage: number;
  no_quoted_quotation_parts_count: number;
  no_quoted_quotation_parts_percentage: number;
  no_response_quotation_parts_count: number;
  no_response_quotation_parts_percentage: number;
  charts: RequestsReportChart[];
  tables: RequestsReportTable[];
};

export type RequestsReportTable = {
  id: string;
  rows: RequestsReportTableRow[];
};

type RequestsReportTableRow = {
  id?: number;
  picture?: string;
  supplier_name: string;
  parts_sent_count: number;
  response_rate_percentage: number;
  conversion_rate_percentage: number;
  total_quoted_value: number;
  total_awarded_value: number;
  average_time_to_quote_days: number;
};

export type RequestsReportChart = {
  id: string;
  x_axis: string[];
  y_axes: Yaxis[];
};

export type AnalyticTrend = {
  trend: "up" | "down" | null;
  trend_value: number;
};

export type OrdersReport = {
  items_count: number;
  suppliers_count: number;
  total_price: number;
  on_time_delivery_percentage: number;
  avg_acknowledgment_time: number;
  on_time_count: number;
  on_time_percentage: number;
  late_count: number;
  tables: OrdersReportTable[];
};

export type OrdersReportTable = {
  id: string;
  rows: OrdersReportSupplierDetailsTableRow[] | OrdersReportTeamWorkloadTableRow[];
};

export type OrdersReportSupplierDetailsTableRow = {
  id?: number;
  picture?: string;
  supplier_name: string;
  items_count: number;
  avg_time_to_acknowledge: number;
  shipped_count: number;
  on_time_count: number;
  on_time_percentage: number;
  total_value: number;
  date_change_percentage: number;
  price_change_percentage: number;
  item_recieved_trend: AnalyticTrend;
  on_time_trend: AnalyticTrend;
};

export type OrdersReportTeamWorkloadTableRow = {
  fullname: string;
  picture?: string;
  po_sent_count: number;
  po_sent_trend: AnalyticTrend;
};

export enum POAnalyticsChartTypes {
  VOLUME_CHART = "po_volume",
  EXPECTED_RECEIPT_VALUE = "expected_receipt_value",
  ON_TIME_DELIVERY = "on_time_delivery",
  ITEM_LATENESS = "item_lateness",
}

export type OrdersReportChart = {
  id: string;
  x_axis: string[];
  y_axes: Yaxis[];
};

export type SupplierReport = {
  active_suppliers: number;
  total_spent: number;
  charts: SupplierReportChart[];
};

export type SupplierReportChart = {
  id: string;
  x_axis: string[];
  y_axes: Yaxis[];
};

type Yaxis = {
  id: string;
  values: number[];
};

export type FetchRequestsReportInput = AnalyticFilters;

export type FetchOrdersReportInput = AnalyticFilters;

export type FetchOrdersChartInput = AnalyticFilters & { chart_type: POAnalyticsChartTypes };
